import React, { Component, Fragment } from "react";

import { PlayerProfile } from "./player-profile";
import { TeamProfileEdit } from "./team-profile-edit";
import { UploadPage } from "./upload-page";

import { getAge, resetScroll } from "../util";
import { initRecaptcha } from "../recaptcha";

import Axios from "axios";
import * as moment from "moment";

export class TeamProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            teamTypeId: null,
            teamId: this.props.teamId || null,

            players: [],

            selectedTeamTypeId:
                props.teamTypes.length === 1 ? props.teamTypes[0].id : null,

            uploadPlayerId: null,

            showValidation: false,
            confirmed: false,
            validation: null,

            readOnly: false,
            showTeamConfirmed: false,

            captchaToken: null,
            showCaptchaError: false,
        };

        this.newPlayer = this.newPlayer.bind(this);
        this.confirmationChanged = this.confirmationChanged.bind(this);
        this.verifyRecaptchaCallback = this.verifyRecaptchaCallback.bind(this);

        this.selectCidadeTvTem = React.createRef();
        this.setCaptchaContainer = this.setCaptchaContainer.bind(this);
    }

    componentDidMount() {
        this.loadTeamInfo();
        resetScroll();

        // Se for uma nova inscrição, iniciar recaptcha
        if (!this.state.teamId) {
            initRecaptcha(() => {
                this.renderRecaptcha();
            });
        }
    }

    /* RECAPTCHA */
    setCaptchaContainer(el) {
        this._recaptchaContainer = el;

        if (el) this.renderRecaptcha();
        else this._recaptchaRendered = false;
    }

    renderRecaptcha() {
        if (this._recaptchaRendered || !this._recaptchaContainer) return;

        if (!window.grecaptcha) return;

        this._recaptchaRendered = true;
        let siteKey = this.props.config.siteKey;
        let captchaId = window.grecaptcha.render(this._recaptchaContainer, {
            sitekey: siteKey,
            callback: this.verifyRecaptchaCallback,
            "expired-callback": () => this.verifyRecaptchaCallback(null),
        });

        this._recaptchaId = captchaId;
    }

    verifyRecaptchaCallback(token) {
        this.setState({
            captchaToken: token,
            showCaptchaError: token ? false : this.state.showCaptchaError,
        });
    }

    resetCaptcha() {
        if (this._recaptchaRendered) {
            window.grecaptcha.reset(this._recaptchaId);

            this.setState({ showCaptchaError: true });
        }
    }

    /* RECAPTCHA */

    cancel(startNew = false) {
        let onSave = this.props.onSave;
        if (onSave) onSave(null, startNew);
    }

    confirmationChanged(e) {
        this.setState({
            confirmed: e.target.checked,
            showValidation: true,
        });
    }

    confirmTeam() {
        let url = `/api/teams/${this.state.teamId}/confirm`;

        Axios.get(url).then((res) => {
            let d = res.data;
            if (d.status === "OK") {
                this.setState({
                    showTeamConfirmed: true,
                });
            }
        });
    }

    render() {
        if (!this.state.teamTypeId) {
            if (this.state.teamId) return <div>Carregando</div>;

            return this.renderTeamTypeSelection();
        }

        if (this.state.showTeamConfirmed) {
            return this.renderTeamConfirmed();
        }

        if (this.state.editMode) {
            return this.renderEditMode();
        }

        let player = this.state.player;
        if (player) {
            return this.renderPlayer();
        }

        let uploadPlayerId = this.state.uploadPlayerId;
        if (uploadPlayerId) {
            return (
                <UploadPage
                    playerId={uploadPlayerId}
                    onSave={(e) => this.onSaveUploads(e)}
                    config={this.props.config}
                />
            );
        }

        // Informações da inscrição
        let teamType = this.props.teamTypes.find(
            (t) => t.id === this.state.teamTypeId
        );
        let teamTypeDesc = teamType.teamType;

        let players = this.state.players;

        let themes = this.props.themes;
        let selectedThemeIds = this.state.themes || [];
        let selectedThemes = [];
        themes.forEach((theme) => {
            if (!selectedThemeIds.includes(theme.id)) return;

            selectedThemes.push(
                theme.code === "special_dog"
                    ? theme.title
                    : `${theme.title} (${theme.code})`
            );
        });

        let title = this.props.teamId
            ? "Detalhes da inscrição"
            : "Nova inscrição";

        let cidadeTxt = (this.state.cidade || {}).cidade || "";

        let isIndividual = [1, 2, 6].includes(this.state.teamTypeId);
        let canAddPlayer = players.length < (isIndividual ? 1 : 10);

        // Validação
        let validation = this.state.validation || { errors: [] };
        let canConfirm = this.state.confirmed && validation.status === "OK";

        let playersValidationMsg = null;
        let playersSpecValidationMsg = {};
        let teamValidationMsg = null;

        const fnNewErrorMsg = (msg) => (
            <div className="validator-msg">{msg}</div>
        );

        this.state.showValidation &&
            validation.errors.forEach((error) => {
                switch (error.type) {
                    case "players":
                        playersValidationMsg = fnNewErrorMsg(error.message);
                        break;
                    case "player":
                        playersSpecValidationMsg[error.playerId] =
                            fnNewErrorMsg(error.message);
                        break;
                    case "team":
                        teamValidationMsg = fnNewErrorMsg(error.message);
                        break;

                    default:
                }
            });

        let readOnly = !!this.state.readOnly || !!this.props.expired;

        return (
            <div className="form">
                <h1 className="form-title">{title}</h1>

                <div className="box">
                    <h2>Participantes</h2>

                    {playersValidationMsg}

                    {players.length === 0 ? (
                        <i className="part-info">
                            Nenhum participante inscrito
                        </i>
                    ) : (
                        players.map((player) => (
                            <div
                                key={`p-${player.id}`}
                                className="player-entry"
                            >
                                <h3>{player.name}</h3>
                                <div>
                                    <b>Data Nasc.: </b>
                                    {moment
                                        .utc(player.birthDate)
                                        .format("DD/MM/YYYY")}
                                    &nbsp; ({getAge(player.birthDate)})
                                </div>

                                {playersSpecValidationMsg[player.id]}

                                <div className="player-actions">
                                    <button
                                        className="button primary sm"
                                        disabled={readOnly}
                                        onClick={(e) => this.editPlayer(player)}
                                    >
                                        Editar
                                    </button>
                                    <button
                                        className="button primary sm"
                                        disabled={readOnly}
                                        onClick={(e) =>
                                            this.setState({
                                                uploadPlayerId: player.id,
                                            })
                                        }
                                    >
                                        Enviar documentos
                                    </button>

                                    <button
                                        className="button red sm"
                                        disabled={readOnly}
                                        onClick={(e) =>
                                            this.removePlayer(player)
                                        }
                                    >
                                        Excluir
                                    </button>
                                </div>
                            </div>
                        ))
                    )}

                    {readOnly || (!canAddPlayer && isIndividual) ? null : (
                        <Fragment>
                            <br />
                            <button
                                className="button primary"
                                disabled={this.state.saving || !canAddPlayer}
                                onClick={this.newPlayer}
                            >
                                Adicionar participante
                            </button>
                        </Fragment>
                    )}
                </div>

                <div className="box">
                    <h2>Informações do Filme</h2>

                    <ReadOnlyField label="Categoria" value={teamTypeDesc} />
                    <ReadOnlyField label="Cidade" value={cidadeTxt} />
                    <ReadOnlyField label="Título" value={this.state.title} />
                    <ReadOnlyField
                        label="Sinopse"
                        value={this.state.description}
                    />
                    <ReadOnlyField
                        label="Temas"
                        value={selectedThemes.join("\n")}
                    />
                    <ReadOnlyField label="Link" value={this.state.link} />

                    {teamValidationMsg}

                    {readOnly ? null : (
                        <button
                            className="button primary"
                            onClick={(e) => this.setState({ editMode: true })}
                        >
                            Editar informações
                        </button>
                    )}
                </div>

                {readOnly ? null : (
                    <Fragment>
                        <div className="field checkbox ckb-confirmation">
                            <input
                                type="checkbox"
                                id="ckbConfirmation"
                                name="confirmation"
                                onChange={this.confirmationChanged}
                                checked={this.state.confirmed}
                            />
                            <label htmlFor="ckbConfirmation">
                                Declaro que todas as informações contidas nesta
                                ficha são verdadeiras e concordo com os
                                dispositivos constantes em todos os termos do
                                &nbsp;
                                <a
                                    href="/assets/seligaai-regulamento.pdf"
                                    target="_blank"
                                >
                                    regulamento
                                </a>
                                &nbsp; do Se Liga Aí
                            </label>
                        </div>

                        <button
                            className="button green"
                            disabled={!canConfirm}
                            onClick={(e) => this.confirmTeam()}
                        >
                            Concluir inscrição
                        </button>
                    </Fragment>
                )}

                <button className="button" onClick={(e) => this.cancel()}>
                    Voltar
                </button>
            </div>
        );
    }

    // Confirmed
    renderTeamConfirmed() {
        return (
            <div className="form confirmed">
                <p className="main-info">
                    Recebemos as informações da sua inscrição.
                </p>

                <p>
                    Nossa equipe irá avaliar os dados e documentação e você
                    receberá uma confirmação de participação por e-mail.
                </p>

                <p>Boa sorte!</p>

                <p>
                    Equipe TV TEM
                    <br />
                    <a href="mailto:eventos@tvtem.com">eventos@tvtem.com</a>
                </p>

                <div className="new-movie-question">
                    <h3>
                        Você quer inscrever mais um filme em outra categoria?
                    </h3>
                    <button
                        className="button primary"
                        onClick={(e) => this.cancel(true)}
                    >
                        Sim
                    </button>
                    <button className="button" onClick={(e) => this.cancel()}>
                        Não
                    </button>
                </div>
            </div>
        );
    }

    // Edit mode
    onSaveTeamProfile(team) {
        let newState = { editMode: false };
        if (team) {
            newState = {
                ...newState,
                title: team.title,
                description: team.description,
                link: team.link,
                teamId: team.teamId,
                token: null,
            };
        }

        this.setState(newState, () => this.loadTeamInfo());
        resetScroll();
    }

    renderEditMode() {
        const propsToCopy = [
            "teamId",
            "teamTypeId",
            "description",
            "link",
            "title",
            "cidade",
            "cidadeId",
        ];

        let props = {};
        propsToCopy.forEach((p) => (props[p] = this.state[p]));
        props.selectedThemes = this.state.themes || [];

        return (
            <TeamProfileEdit
                {...props}
                token={this.state.token}
                themes={this.props.themes}
                teamTypes={this.props.teamTypes}
                onSave={(e) => this.onSaveTeamProfile(e)}
            />
        );
    }

    // Upload de documentos
    onSaveUploads(d) {
        let newState = { uploadPlayerId: null };

        this.setState(newState, () => this.loadTeamInfo());
        resetScroll();
    }

    // Participantes
    loadTeamInfo() {
        let teamId = this.state.teamId;
        if (!teamId) return;

        let url = `/api/teams/${teamId}`;
        Axios.get(url).then((res) => {
            let d = res.data;
            if (d.status === "OK") {
                let team = d.team;
                if (!team) {
                    alert("Inscrição inválida");
                    return;
                }

                team.teamId = team.id;
                delete team["id"];

                let cidade = {};
                for (let k in team) {
                    let m = k.match(/^cidade\.(.+)/);
                    if (m) {
                        cidade[m[1]] = team[k];
                    }
                }
                team["cidade"] = cidade;
                team["validation"] = d.validation;
                team["readOnly"] = team.status !== 1;

                this.setState(team);
            }
        });
    }

    newPlayer() {
        this.setState({
            player: {},
        });
    }

    editPlayer(player) {
        this.setState({
            player: { ...player },
        });
    }

    removePlayer(player) {
        if (!window.confirm("Tem certeza que deseja exlcluir o participante?"))
            return;

        Axios.delete(`/api/player/${player.id}`).then((res) => {
            let d = res.data;
            if (d.status === "OK") {
                this.loadTeamInfo();
            }
        });
    }

    onSavePlayer(d) {
        let newState = { player: null };
        if (d && d.status === "OK") {
            if (d.teamId) {
                newState["teamId"] = d.teamId;
                newState["token"] = null;
            }

            // temporariamente adicionando player
            if (d.player) {
                let players = [...this.state.players];
                players.push(d.player);
                players.sort((a, b) =>
                    a.name.localeCompare(b.name, "pt-BR", {
                        sensitivity: "base",
                    })
                );

                newState["players"] = players;
            }
        }

        this.setState(newState, () => this.loadTeamInfo());
        resetScroll();
    }

    renderPlayer() {
        let state = this.state;
        let player = state.player;

        let team = {
            id: state.teamId,
            teamTypeId: state.teamTypeId,
            cidadeId: state.cidadeId,
        };

        let title =
            player && player.id ? "Editar participante" : "Novo participante";

        return (
            <PlayerProfile
                config={this.props.config}
                token={this.state.token}
                team={team}
                player={player}
                onSave={(d) => this.onSavePlayer(d)}
                title={title}
            />
        );
    }

    // Seleção do tipo de inscrição
    selectTeamType(teamTypeId) {
        this.setState({
            selectedTeamTypeId: teamTypeId,
        });
    }

    saveTeamType() {
        if (this.canContinueTeamCreation) {
            // Commit
            const fnCommitTeam = (token) => {
                let selectCidade = this.selectCidadeTvTem.current;
                let cidadeId = parseInt(selectCidade.value, 10);
                let cidade = this.props.tvtemCities.find(
                    (c) => c.id === cidadeId
                );

                this.setState({
                    teamTypeId: this.state.selectedTeamTypeId,
                    cidadeId,
                    cidade,
                    token,
                });
            };

            // Verificando o token recaptcha
            let token = this.state.captchaToken;
            Axios.post("/api/teams/new", { token }).then((res) => {
                let d = res.data;

                switch (d.status) {
                    case "OK":
                        fnCommitTeam(d.token);
                        break;

                    case "InvalidRecaptcha":
                        this.resetCaptcha();
                        break;

                    default:
                }
            });
        }
    }

    get canContinueTeamCreation() {
        if (!this.state.captchaToken) return false;

        let selectCidade = this.selectCidadeTvTem.current;
        if (selectCidade && selectCidade.value) {
            if (this.state.selectedTeamTypeId) return true;
        }

        return false;
    }

    renderTeamTypeSelection() {
        let teamTypes = this.props.teamTypes;
        let tvtemCities = this.props.tvtemCities;

        return (
            <div className="form">
                <h1 className="form-title">Nova inscrição</h1>

                <p>Selecione a categoria</p>

                <div className="team-types">
                    {teamTypes.map((teamType) => {
                        let classes = ["button"];
                        if (this.state.selectedTeamTypeId === teamType.id)
                            classes.push("primary");

                        return (
                            <button
                                className={classes.join(" ")}
                                key={`btnTeamType${teamType.id}`}
                                onClick={() => this.selectTeamType(teamType.id)}
                            >
                                {teamType.teamType}
                            </button>
                        );
                    })}
                </div>

                <p>Selecione a sua cidade</p>
                <div className="field">
                    <select
                        ref={this.selectCidadeTvTem}
                        onChange={(e) => this.forceUpdate()}
                    >
                        <option value="" className="i">
                            Selecione uma cidade
                        </option>
                        {tvtemCities.map((city) => (
                            <option key={`c${city.id}`} value={city.id}>
                                {city.cidade}
                            </option>
                        ))}
                    </select>
                </div>

                {!this.state.showCaptchaError ? null : (
                    <div className="validator-msg">
                        Houve um erro na validação do captcha. Por favor, valide
                        novamente o botão Não sou um robô.
                    </div>
                )}
                <div
                    ref={this.setCaptchaContainer}
                    className="recaptcha-container"
                ></div>

                <p>
                    <button
                        className="button primary"
                        onClick={(e) => this.saveTeamType()}
                        disabled={!this.canContinueTeamCreation}
                    >
                        Continuar
                    </button>

                    <button className="button" onClick={(e) => this.cancel()}>
                        Voltar
                    </button>
                </p>
            </div>
        );
    }
}

const ReadOnlyField = (props) => {
    let v = (props.value || "").toString().trim();

    return (
        <div className="field ro">
            <label>{props.label}</label>
            {v ? <span>{v}</span> : <i>Não informado</i>}
        </div>
    );
};
