const moment = require("moment");

const validateCpf = cpf => {
    cpf = cpf.replace(/[^\d]/g, "");
    if (cpf.length !== 11) return false;

    if (cpf === "00000000000") return false;

    let dv = parseInt(cpf.substr(9));

    let sum = 0;
    let sum2 = 0;
    for (let k=0; k<10; k++) {
        let d = parseInt(cpf[k]);

        if (k < 9) sum += d * (10 - k);
        sum2 += d * (11 - k);
    }

    let rem = (sum * 10) % 11;
    if (rem >= 10) rem = 0;

    let rem2 = (sum2 * 10) % 11;
    if (rem2 >= 10) rem2 = 0;

    return (rem * 10 + rem2) === dv;
}

const requiredValidator = (field) => {
    let v = field.value || "";
    return v.trim().length > 0;
};

const getAge = (birthDate) => {
    let bd = moment.utc(birthDate);
    let now = moment().utc(true);

    let df = moment.duration(now.diff(bd)).as('years');
    return `${ Math.floor(df) } anos`;
};

const shortenName = (name) => {
    let tn = name.toString().trim();
    return tn.replace(/\s.*\s/, " ");
};

const resetScroll = () => {
    window.document.scrollingElement.scrollTop = 0;
};

// Validation
const validateForm = (validators, validatorKey = null, state = null) => {
    let isValid = true;

    let newValidators = {};
    let firstInvalidField = null;

    for(let key in validators) {
        let validator = validators[key];

        let skip = validatorKey && key !== validatorKey;

        let field = document.querySelector(`input[name=${ key }]`);
        if (field && !skip) {

            if (Array.isArray(validator)) {

                let valValid = true;
                let newValidator = validator.map(
                    validator2 => {
                        let valid = validator2.validate(field);
                        if (!valid) valValid = false;

                        return {
                            ...validator2,
                            valid,
                        };
                    }
                );

                newValidators[key] = newValidator;

                if (!valValid && !firstInvalidField)
                    firstInvalidField = field;

                continue;
            }

            let valid = validator.validate(field);
            isValid = isValid && valid;

            if (!valid && !firstInvalidField) firstInvalidField = field;

            if (valid !== validator.valid) {
                newValidators[key] = {
                    ...validator,
                    valid,
                };
                continue;
            }
        }

        newValidators[key] = validator;
    }

    if (firstInvalidField)
        firstInvalidField.focus();

    let isFormValid = !firstInvalidField;
    return [isFormValid, newValidators];
}

const linkValidator = (field) => {
    let reUrl = /^https?:\/\/.*/;
    let val = field.value.trim();

    return !val || reUrl.test(val);
};

const cpfValidator = (field) => {
    let v = (field.value || "").replace(/[^\d]/g, "");
    if (v === "") return true;

    return validateCpf(v);
};

const dateValidator = (field) => {
    let m = moment.utc(field.value);
    if (!m.isValid()) return false;

    if (field.min) {
        let mMin = moment.utc(field.min);
        if (m.isBefore(mMin)) return false;
    }

    if (field.max) {
        let mMax = moment.utc(field.max);
        if (m.isAfter(mMax)) return false;
    }

    return true;
};

const phoneValidator = (field) => {
    // Telefones precisam ter 10 ou 11 números, ou estar vazio
    let vn = field.value.replace(/[^\d]/g, '');
    return [0, 10, 11].includes(vn.length);
};

const formatCpf = (cpf) => {
    let vn = cpf.replace(/[^\d]/g, '');
    let v = vn.replace(/^(\d{3})(\d{3})(\d{3})(\d{1,2}).*$/, '$1.$2.$3-$4');
    v = v.replace(/^(\d{3})(\d{3})(\d)/, '$1.$2.$3');
    v = v.replace(/^(\d{3})(\d)/, '$1.$2');

    return v;
};

const formatCep = (cep) => {
    let v = cep.replace(/[^\d]/g, '');
    let vf = v.replace(/^(\d{5})(\d{1,3}).*$/, '$1-$2');

    return vf;
}

const formatPhone = (phone, full = false) => {
    if (full) phone = (phone || "").toString().trim();

    let vn = phone.replace(/[^\d]/g, '');
    if (full && ![10, 11].includes(vn.length))
        return "";

    if (vn.length > 2)
        vn = vn.replace(/^(\d\d)/, "($1) ");

    if (/\d{9}/.test(vn))
        vn = vn.replace(/(\d{5})(\d{4}).*$/, "$1-$2");
    else
        vn = vn.replace(/(\d{4})(\d{1,4}).*$/, "$1-$2");

    return vn;
}

// Validation

export {
    validateCpf,
    requiredValidator,
    getAge,
    shortenName,
    resetScroll,

    validateForm,
    linkValidator, cpfValidator, dateValidator, phoneValidator,

    formatCep, formatCpf, formatPhone,

};
