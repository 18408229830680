import React from "react";

export const ProfileField = props => {
    let validators = props["validators"];
    let validatorKey = props["validator-key"];

    let validatorElem = null;
    let attrs = {};

    if (validatorKey && validators["_enabled"]) {
        let validator = validators[validatorKey];
        let valid = true;
        let message;

        if (Array.isArray(validator)) {
            for (let k = 0; k < validator.length; k++) {
                let vv = validator[k];
                if (!vv.valid) {
                    valid = false;
                    message = vv.message;
                }
            }
        }
        else if (!validator.valid) {
            valid = false;
            message = validator.message;
        }

        if (!valid) {
            attrs["aria-invalid"] = true;

            validatorElem =
                <span className="validator-message">
                    { message }
                </span>;
        }
    }

    let className = "field";
    if (props.className)
        className = `${ className } ${ props.className }`;

    return <div className={ className } { ...attrs }>
        <label>{ props.label }{ validatorElem }</label>
        { props.children }
    </div>;
};
