const ensureRecaptcha = () => {
    if (document.getElementById("recaptcha-src"))
        return true;

    let head = window.document.head;
    let script = document.createElement("script");
    script.id = "recaptcha-src";
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;
    script.src = "https://www.google.com/recaptcha/api.js?onload=recaptchaOnLoadCb&render=explicit";
    head.appendChild(script);

    return false;
};

window.recaptchaOnLoadCb = () => {
    if (window._recaptchaCbs) {
        window._recaptchaCbs.forEach(cb => cb());
        window._recaptchaCbs = null;
    }
};

const initRecaptcha = cb => {
    if (ensureRecaptcha()) {
        cb();
    }
    else {
        if (!window._recaptchaCbs)
            window._recaptchaCbs = [];
        window._recaptchaCbs.push(cb);
    }
}

export {
    initRecaptcha,
};
