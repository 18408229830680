import React, { Component } from "react";
import { Link } from "react-router-dom";

/// Esta página exibe as inscrições atreladas a conta de usuário que está lgoada
/// no sistema. Deve permitir listar as inscrições, adicionar / remover
/// participantes e verificar as pendências da inscrição.

/// Estados:
/// - Carregando
/// - Usuário precisa completar seu cadastro
/// - Usuário não tem nenhuma inscrição em progresso
/// - Listar inscrições

/// 2020-08-03:
/// Simplificando o fluxo
/// Agora o usuário não precisa ter um profile completo

/// 2021-08-14:
/// Adicionando cadastro Special Dog

import { AppContext } from "../app-context";
import { TeamProfile } from "./team-profile";
import { getAge } from "../util";

import Axios from "axios";

import "./dashboard-page.css";

const PAGE_MODE = {
    LOADING: 0,
    PROFILE_INCOMPLETE: 1,
    LIST_TEAMS: 2,
    NEW_TEAM: 3,
    EDIT_TEAM: 4,
};

const SPECIAL_DOG_TEAM_TYPE_ID = 5;

export class DashboardPage extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            mode: PAGE_MODE.LOADING,
            teamTypesFiltered: null,
        };

        this.saveTeam = this.saveTeam.bind(this);
    }

    componentDidMount() {
        this.loadDashboard();

        document.body.classList.add("main-page");
    }

    componentWillUnmount() {
        document.body.classList.remove("main-page");
    }

    loadDashboard() {
        // Listar as inscrições do usuário
        Axios.get("/api/teams").then((res) => {
            let d = res.data;
            if (d.status === "OK") {
                // Se o usuário ainda não possuir uma inscrição, iniciar
                // nova inscrição automaticamente
                let teams = d.teams || [];
                let themes = d.themes || [];
                let teamTypes = d.teamTypes || [];
                let tvtemCities = d.tvtemCities || [];

                const expired = this.context.expired;
                this.setState({
                    mode:
                        (teams.length && !this.state.startNew) || expired
                            ? PAGE_MODE.LIST_TEAMS
                            : PAGE_MODE.NEW_TEAM,
                    teams,
                    themes,
                    teamTypes,
                    tvtemCities,
                    startNew: false,
                });
            }
        });
    }

    editTeam(team) {
        if (!team) {
            let newState = {
                mode: PAGE_MODE.NEW_TEAM,
                teamId: null,
                teamTypesFiltered: null,
            };

            // Se o usuário já tem uma inscrição para Special Dog, podemos
            // pular a tela de escolha
            const specialDogTeam = this.state.teams.find(
                (t) => t.teamTypeId === SPECIAL_DOG_TEAM_TYPE_ID
            );
            if (specialDogTeam) {
                newState = {
                    ...newState,
                    ...this.filterThemesAndTeamTypes(false),
                };
            }

            this.setState(newState);
            return;
        }

        // Para editar um team, precisar filtrar suas categorias
        this.setState({
            mode: PAGE_MODE.EDIT_TEAM,
            teamId: team.id,
            ...this.filterThemesAndTeamTypes(
                team.teamTypeId === SPECIAL_DOG_TEAM_TYPE_ID
            ),
        });
    }

    deleteTeam(team) {
        if (!window.confirm("Tem certeza que deseja excluir esta inscrição?"))
            return;

        let url = `/api/teams/${team.id}`;
        Axios.delete(url).then((res) => {
            let d = res.data;
            switch (d.status) {
                case "OK":
                    break;

                default:
                    window.alert("Ocorreu um erro na solicitação\n" + d.status);
                    break;
            }

            this.loadDashboard();
        });
    }

    render() {
        return <div className="dashboard">{this.renderContent()}</div>;
    }

    saveTeam(t, startNew = false) {
        this.setState({
            mode: PAGE_MODE.LOADING,
            teamTypesFiltered: null,
            startNew,
        });

        this.loadDashboard();
    }

    renderContent() {
        const expired = this.context.expired;

        switch (this.state.mode) {
            case PAGE_MODE.LIST_TEAMS:
                return this.renderList();

            case PAGE_MODE.NEW_TEAM:
                if (this.state.teamTypesFiltered === null)
                    return this.renderTeamTypeSelection();

                return (
                    <TeamProfile
                        key="new-team"
                        onSave={this.saveTeam}
                        config={this.context.config}
                        themes={this.state.themesFiltered}
                        teamTypes={this.state.teamTypesFiltered}
                        tvtemCities={this.state.tvtemCities}
                    />
                );

            case PAGE_MODE.EDIT_TEAM:
                return (
                    <TeamProfile
                        key="edit-team"
                        onSave={this.saveTeam}
                        config={this.context.config}
                        teamId={this.state.teamId}
                        themes={this.state.themesFiltered}
                        expired={expired}
                        teamTypes={this.state.teamTypesFiltered}
                    />
                );

            default:
                return null;
        }
    }

    renderList() {
        const expired = this.context.expired;

        return (
            <div className="team-list form">
                <h1 className="form-title">Minhas inscrições</h1>

                {!expired ? null : (
                    <b className="msg-expired">Inscrições encerradas</b>
                )}

                {this.state.teams.map((team) => this.renderTeam(team))}

                <button
                    className="button primary"
                    disabled={expired}
                    onClick={(e) => this.editTeam(null)}
                >
                    Nova inscrição
                </button>

                <Link to="/" className="button">
                    Voltar
                </Link>
            </div>
        );
    }

    renderTeam(team) {
        let teamTypes = this.state.teamTypes;
        let teamType = teamTypes.find((t) => t.id === team.teamTypeId);

        let playersGrid;
        if (team.players.length > 0) {
            playersGrid = team.players.map((player) => (
                <tr key={`p-${player.id}`} className="player-list">
                    <td>{player.name}</td>
                    <td>{getAge(player.birthDate)}</td>
                    <td></td>
                </tr>
            ));
        } else {
            playersGrid = (
                <tr className="player-list">
                    <td colSpan="3">
                        <i>Nenhum participante inscrito</i>
                    </td>
                </tr>
            );
        }

        let statusInfo = null;
        switch (team.status) {
            case 2:
            case 3:
                statusInfo = <p className="warn green">Inscrição concluída</p>;
                break;

            default:
        }

        const expired = this.context.expired;

        return (
            <div key={`t-${team.id}`} className="team box">
                <div className="label">Tipo:</div>
                {teamType.teamType}
                <div className="label">Título:</div>
                {team.title} &nbsp;
                <div className="label">Participantes:</div>
                <table cellPadding="0" cellSpacing="0" className="tbl">
                    <colgroup>
                        <col />
                        <col width="120px" />
                        <col width="1px" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Idade</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>{playersGrid}</tbody>
                </table>
                {statusInfo}
                <button
                    className="button primary sm"
                    onClick={(e) => this.editTeam(team)}
                >
                    Detalhes
                </button>
                {team.status > 1 ? null : (
                    <button
                        className="button red sm"
                        disabled={expired}
                        onClick={(e) => this.deleteTeam(team)}
                    >
                        Excluir
                    </button>
                )}
            </div>
        );
    }

    filterThemesAndTeamTypes(isSpecialDog) {
        const { teamTypes, themes } = this.state;

        const teamTypesFiltered = teamTypes.filter(
            (t) => !!t.info.special_dog === isSpecialDog
        );
        const themesFiltered = themes.filter(
            (t) => (t.code === "special_dog") === isSpecialDog
        );

        return { teamTypesFiltered, themesFiltered };
    }

    renderTeamTypeSelection() {
        const selectType = (isSpecialDog) => {
            this.setState(this.filterThemesAndTeamTypes(isSpecialDog));
        };

        return (
            <div className="team-list form">
                <h1 className="form-title">Selecionar categoria</h1>

                <div className="team-types">
                    <button
                        className="button primary"
                        onClick={(e) => selectType(false)}
                    >
                        Individual, Grupo e Universitário
                    </button>
                    <button
                        className="button secondary"
                        onClick={(e) => selectType(true)}
                    >
                        Animal de estimação
                        <br />
                        <img
                            className="special-dog-logo"
                            src="/2021/special-dog.png"
                        />
                    </button>
                </div>
            </div>
        );
    }
}
