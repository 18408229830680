import React, { Component, Suspense } from 'react';
import {
    Switch, Route, BrowserRouter as Router, Link
} from 'react-router-dom';
import Axios from "axios";

/// App é uma máquina de estados:
/// - Loading:
///   não carregamos o estado ainda, não sabemos se o usuário está logado
/// - Guest:
///   usuário não está logado
/// - UserLoggedIn:
///   usuário está logado

import { ReactComponent as LogoSvg } from "./logo-tvtem.svg";

import { AppContext, AppContextClass } from "./app-context";

import { WelcomePage } from "./pages/welcome-page";
import { DashboardPage } from "./pages/dashboard-page";

import { shortenName } from "./util";

// Admin Components
const AdminInscricoes = React.lazy(() => import('./pages/admin-inscricoes'));
const AdminInscricao = React.lazy(() => import('./pages/admin-inscricao'));
const AdminRatings = React.lazy(() => import('./pages/admin-ratings'));
const AdminRating = React.lazy(() => import('./pages/admin-rating'));

const STATE = {
    LOADING: 0,
    GUEST: 1,
    USER_LOGGED_IN: 2,
};

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            current: STATE.LOADING,
            context: null,
        };
    }

    componentDidMount() {
        this.queryState();
    }

    queryState() {
        this.setState({ current: STATE.LOADING });

        Axios.get("/api/state").then(
            res => {
                let d = res.data;
                if (d.status === "OK") {
                    let user = d.user;
                    let newPageState = user ?
                        STATE.USER_LOGGED_IN : STATE.GUEST;

                    let newState = {
                        current: newPageState,
                    };

                    if (user) {
                        let newContext = new AppContextClass();
                        newContext.user = user;
                        newContext.player = d.player;
                        newContext.config = d.config;
                        newContext.expired = !!d.expired;
                        newState.context = newContext;
                    }

                    this.setState(newState);
                }
            }
        )
    }

    render() {
        let context = this.state.context;
        let user = context && context.user;

        let userInfo = null;
        if (user) {
            userInfo = <div className="user-info">
                <div>
                    Olá <b>{ shortenName(user.name) }</b><br />
                    <a href="/api/sign-out">Não sou eu? Clique aqui para sair</a>
                </div>
            </div>;
        }

        return (
            <AppContext.Provider value={ context }>
                <header>
                    <div className="logo">
                        <LogoSvg />
                    </div>
                    <div className="fill">
                    </div>
                    { userInfo }
                </header>
                <Router>
                    { this.renderAdminLinks() }
                    { this.renderContent() }
                </Router>
            </AppContext.Provider>
        );
    }

    renderAdminLinks() {
        let context = this.state.context;
        let user = context && context.user;
        const isAvaliador = user && (user.isAvaliador.length||[]) > 0;

        if (!user || !(user.isAdmin || isAvaliador)) {
            return null;
        }

        return (
            <div className="admin-links">
                { !user.isAdmin ? null :
                <Link to="/admin/inscricoes">Listagem de inscrições</Link>
                }
                <Link to="/admin/avaliacao">Avaliação</Link>
            </div>
        );
    }

    renderContent() {
        let curState = this.state.current;

        switch (curState) {
            case STATE.LOADING:
                return <div>Carregando...</div>;

            case STATE.GUEST:
                return <WelcomePage />;

            case STATE.USER_LOGGED_IN:
                return this.renderRoutes();

            default:
                return <div>State error</div>;
        }
    }

    renderRoutes() {
        const suspFallback = <div>Carregando...</div>;
        return (
            <Switch>
                <Route path="/inscricoes">
                    <DashboardPage />
                </Route>

                <Route path="/admin/inscricoes/:id" render={ e =>
                    <Suspense fallback={ suspFallback }>
                        <AdminInscricao id={ e.match.params.id }
                            history={ e.history } />
                    </Suspense>
                }/>

                <Route path="/admin/inscricoes">
                    <Suspense fallback={ suspFallback }>
                        <AdminInscricoes />
                    </Suspense>
                </Route>

                <Route path="/admin/avaliacao/:id" render={ e =>
                    <Suspense fallback={ suspFallback }>
                        <AdminRating id={ e.match.params.id }
                            history={ e.history } />
                    </Suspense>
                }/>
                <Route path="/admin/avaliacao">
                    <Suspense fallback={ suspFallback }>
                        <AdminRatings />
                    </Suspense>
                </Route>
                <Route path="/admin/avaliacao">
                    <DashboardPage />
                </Route>

                <Route path="/">
                    <WelcomePage />
                </Route>
            </Switch>
        );
    }
}

export default App;
