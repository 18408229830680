import React, { Component } from "react";
import { ProfileField } from "./profile-field";

import Axios from "axios";

import { validateForm, linkValidator } from "../util";

export class TeamProfileEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            validators: this.getValidators(),
        };

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    getValidators() {
        return {
            _enabled: true,
            link: {
                message: "O campo Link contém um endereço inválido",
                valid: true,
                validate: linkValidator,
            },
        };
    }

    validate(validatorKey = null, state = null) {
        let validators = this.state.validators;
        let res = validateForm(validators, validatorKey, state);

        let isFormValid = res[0];
        let newValidators = res[1];

        if (newValidators) {
            this.setState({
                validators: newValidators,
                ...(isFormValid ? state : null),
            });
        }

        return isFormValid;
    }

    save() {
        let inputs = document.querySelectorAll(".form [name]");
        let fields = {
            teamId: this.props.teamId,
            teamTypeId: this.props.teamTypeId,
            cidadeId: this.props.cidadeId,
            theme: [],
            token: this.props.token,
        };

        inputs.forEach((input) => {
            switch (input.type) {
                case "checkbox":
                    if (input.checked) {
                        fields[input.name].push(input.value);
                    }
                    break;

                default:
                    let v = input.value.trim();
                    fields[input.name] = v || null;
                    break;
            }
        });

        // 2020-08-24:
        // Validação do campo Link
        if (!this.validate()) {
            return;
        }

        this.setState({ saving: true });

        Axios.post("/api/teams", fields).then(
            (res) => {
                this.setState({ saving: false });

                let d = res.data;
                if (d.status === "OK") {
                    let onSave = this.props.onSave;
                    if (onSave) onSave(d);
                }
            },
            () => this.setState({ saving: false })
        );
    }

    cancel() {
        let onSave = this.props.onSave;
        if (onSave) onSave(null);
    }

    render() {
        let validators = this.state.validators;
        let themes = this.props.themes;

        let teamType = this.props.teamTypes.find(
            (t) => t.id === this.props.teamTypeId
        );
        let teamTypeDesc = teamType.teamType;

        return (
            <div className="form">
                <h1 className="form-title">Informações do Filme</h1>

                <div className="box">
                    <div className="field">
                        <label>Tipo da inscrição</label>
                        <input type="text" disabled value={teamTypeDesc} />
                    </div>
                    <div className="field">
                        <label>Cidade</label>
                        <input
                            type="text"
                            disabled
                            value={this.props.cidade.cidade}
                        />
                    </div>

                    <ProfileField
                        label="Título"
                        validators={validators}
                        validator-keyyy="rg"
                    >
                        <input
                            type="text"
                            name="title"
                            defaultValue={this.props.title || ""}
                            maxLength="200"
                        />
                    </ProfileField>
                    <ProfileField
                        label="Sinopse"
                        validators={validators}
                        validator-keyyy="rg"
                    >
                        <textarea
                            name="description"
                            rows="4"
                            defaultValue={this.props.description || ""}
                            maxLength="500"
                        />
                    </ProfileField>

                    <div className="field">
                        <label>Tema</label>

                        {themes.map((theme) => {
                            let id = `ckb-${theme.id}`;
                            let label =
                                theme.code === "special_dog"
                                    ? theme.title
                                    : `${theme.title} (${theme.code})`;

                            let val =
                                themes.length === 1 ||
                                this.props.selectedThemes.includes(theme.id);

                            return (
                                <div key={id} className="field checkbox">
                                    <input
                                        type="checkbox"
                                        id={id}
                                        name="theme"
                                        defaultChecked={val}
                                        value={theme.id}
                                        readOnly={themes.length === 1}
                                    />
                                    <label htmlFor={id}>{label}</label>
                                </div>
                            );
                        })}
                    </div>

                    <ProfileField
                        label="Link"
                        validators={validators}
                        validator-key="link"
                    >
                        <div className="field-message">
                            O campo link deve conter um endereço da Web por onde
                            os avaliadores do concurso possam assistir ao seu
                            filme. Seguem alguns exemplos de plataformas
                            digitais que serão aceitas: YouTube, Vimeo,
                            OneDrive, Dropbox, Google Drive, e outros.
                        </div>
                        <input
                            type="url"
                            name="link"
                            id="link"
                            defaultValue={this.props.link || ""}
                            placeholder="https://youtube.com/watch?v=exemplo"
                            pattern="^https?://.*"
                            maxLength="255"
                        />
                    </ProfileField>
                </div>

                <button
                    className="button primary"
                    disabled={this.state.saving}
                    onClick={this.save}
                >
                    Salvar
                </button>
                <button className="button" onClick={this.cancel}>
                    Cancelar
                </button>
            </div>
        );
    }
}
