import React from "react";

export class AppContextClass {
    get userId() {
        return this.user.id;
    }
    get userName() {
        return this.user.name;
    }
    get userEmail() {
        return this.user.email;
    }
    get user() {
        return this._user;
    }
    get player() {
        return this._player;
    }

    set user(user) {
        this._user = user;
    }
    set player(player) {
        this._player = player;
    }

    get config() {
        return this._config;
    }
    set config(config) {
        this._config = config;
    }

    get expired() {
        return this._expired;
    }
    set expired(expired) {
        this._expired = expired;
    }

    getMessage(message) {

    }
}

export const AppMessage = {
    TITLE: "title",
};

export const AppContext = React.createContext(new AppContextClass());
