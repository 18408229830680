import React, { Component } from "react";
import Axios from "axios";
import * as moment from "moment";

const MAX_FILE_SIZE = 10 * 1024 * 1024;

export class UploadPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,

            upload: {
                termo: -1,
                "termo-menor": -1,
                selfie: -1,
                matricula: -1,
            },
        };

        this.startUpload = this.startUpload.bind(this);

        this.fileTermo = React.createRef();
        this.fileSelfie = React.createRef();
        this.fileMatricula = React.createRef();
    }

    componentDidMount() {
        this.loadUploadData();
    }

    loadUploadData() {
        let playerId = this.props.playerId;
        let url = `/api/upload/${playerId}`;

        Axios.get(url).then((res) => {
            let d = res.data;
            if (d.status === "OK") {
                delete d["status"];
                d["loading"] = false;

                // Determinando se é um menor
                let minMinorBirthDate = moment.utc(
                    this.props.config["MIN_BIRTH_DATE_MINOR"]
                );
                let birthDate = moment.utc(d["playerBirthDate"]);

                d["isMinor"] = birthDate.isSameOrAfter(minMinorBirthDate);
                d["isUniversitario"] = [6, 7].includes(d["teamTypeId"]);

                this.setState(d);
            }
        });
    }

    setUploadProgress(key, p, cb = null) {
        let upload = {
            ...this.state.upload,
            [key]: p,
        };

        this.setState(
            {
                upload,
            },
            cb
        );
    }

    startUpload(e) {
        let field = e.target;
        if (field.files.length < 1) return;

        let file = field.files[0];

        if (file.size > MAX_FILE_SIZE) {
            alert(
                "O arquivo selecionado é maior que o máximo permitido (10 MB)"
            );
            return;
        }

        let fd = new FormData();
        fd.append("field", field.name);
        fd.append("uploadedFile", file);

        let playerId = this.props.playerId;
        let url = `/api/upload/${playerId}`;

        let config = {
            onUploadProgress: (e) => {
                let p = e.total > 0 ? e.loaded / e.total : 0;
                this.setUploadProgress(field.name, p);
            },
        };

        Axios.post(url, fd, config)
            .then((res) => {
                let d = res.data;

                switch (d.status) {
                    case "OK":
                        break;

                    default:
                        window.alert("Ocorreu um erro:\n" + d.status);
                        break;
                }
            })
            .finally(() =>
                this.setUploadProgress(field.name, -1, () =>
                    this.loadUploadData()
                )
            );
    }

    selectFile(f) {
        switch (f) {
            case 0: // termo
                this.fileTermo.current.click();
                break;

            case 1: // selfie
                this.fileSelfie.current.click();
                break;

            case 2: // matrícula
                this.fileMatricula.current.click();
                break;

            default:
        }
    }

    cancel() {
        let onSave = this.props.onSave;
        if (onSave) onSave(null);
    }

    render() {
        if (this.state.loading) return <div>Carregando</div>;

        let isMinor = this.state.isMinor;
        let isUniversitario = this.state.isUniversitario;

        let urlTermo = isMinor
            ? "/assets/seligaai-termo-menor.pdf"
            : "/assets/seligaai-termo.pdf";

        let termoName = isMinor ? "termo-menor" : "termo";

        let uploads = this.state.uploads || [];

        // Termo
        let existingTermo = null;
        let termo = uploads.find((u) => u.typeId === (isMinor ? 2 : 1));
        if (termo) {
            existingTermo = (
                <p className="warning">
                    <i>
                        Você já enviou um termo para este participante:
                        <b>
                            <a
                                href={termo.publicUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {termo.filename}
                            </a>
                        </b>
                        <span>
                            Se for necessário, clique em "Enviar termo de
                            autorização" para substituir o documento enviado.
                        </span>
                    </i>
                </p>
            );
        }

        // Selfie
        let existingSelfie = null;
        let selfie = uploads.find((u) => u.typeId === 3);
        if (selfie) {
            let msg = isMinor
                ? "Você já enviou a foto do responsável para este participante:"
                : "Você já enviou a foto para este participante:";
            existingSelfie = (
                <p className="warning">
                    <i>
                        {msg}
                        <b>
                            <a
                                href={selfie.publicUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {selfie.filename}
                            </a>
                        </b>
                        <span>
                            Se for necessário, clique em "Enviar selfie" para
                            substituir a foto enviada.
                        </span>
                    </i>
                </p>
            );
        }

        // Matrícula
        let existingMatricula = null;
        let matricula = uploads.find((u) => u.typeId === 4);
        if (matricula) {
            let msg = "Você já enviou um comprovante de matrícula:";
            existingMatricula = (
                <p className="warning">
                    <i>
                        {msg}
                        <b>
                            <a
                                href={matricula.publicUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {matricula.filename}
                            </a>
                        </b>
                        <span>
                            Se for necessário, clique em "Enviar comprovante de
                            matrícula" para substituir o já enviado.
                        </span>
                    </i>
                </p>
            );
        }

        let isUploading = false;
        for (let k in this.state.upload) {
            if (this.state.upload[k] >= 0) {
                isUploading = true;
                break;
            }
        }

        return (
            <div className="form">
                <input
                    type="file"
                    className="hidden"
                    ref={this.fileTermo}
                    name={termoName}
                    onChange={this.startUpload}
                />
                <input
                    type="file"
                    className="hidden"
                    accept="image/*"
                    ref={this.fileSelfie}
                    name="selfie"
                    onChange={this.startUpload}
                />
                <input
                    type="file"
                    className="hidden"
                    accept="image/*"
                    ref={this.fileMatricula}
                    name="matricula"
                    onChange={this.startUpload}
                />

                <h1 className="form-title">Envio de documentos</h1>

                <div className="box">
                    <h2>{this.state.playerName}</h2>

                    {isMinor ? (
                        <h3>Termo de uso de imagem - Menor de idade</h3>
                    ) : (
                        <h3>Termo de uso de imagem</h3>
                    )}

                    <div className="message">
                        É necessário fazer o download do termo de uso de imagem,
                        imprimir, preencher, assinar e enviar uma cópia
                        digitalizada.
                    </div>
                    <p>
                        <a
                            href={urlTermo}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Termo de uso de imagem
                        </a>
                    </p>
                    {existingTermo}

                    {this.state.upload[termoName] < 0 ? null : (
                        <div className="progress">
                            <div
                                style={{
                                    width: `${
                                        this.state.upload[termoName] * 100
                                    }%`,
                                }}
                            />
                        </div>
                    )}
                    <p>
                        <button
                            className="button primary sm"
                            disabled={this.state.upload[termoName] >= 0}
                            onClick={(e) => this.selectFile(0)}
                        >
                            Enviar termo de uso de imagem
                        </button>
                    </p>

                    {!isUniversitario ? null : (
                        <>
                            <h3>Comprovante de matrícula</h3>

                            <div className="message">
                                É necessário enviar uma cópia digitalizada de um
                                comprovante de matrícula na universidade.
                            </div>
                            {existingMatricula}

                            {this.state.upload.matricula < 0 ? null : (
                                <div className="progress">
                                    <div
                                        style={{
                                            width: `${
                                                this.state.upload.matricula *
                                                100
                                            }%`,
                                        }}
                                    />
                                </div>
                            )}
                            <p>
                                <button
                                    className="button primary sm"
                                    disabled={this.state.upload.matricula >= 0}
                                    onClick={(e) => this.selectFile(2)}
                                >
                                    Enviar comprovante de matrícula
                                </button>
                            </p>
                        </>
                    )}

                    <h3>
                        {isMinor
                            ? "Foto do Responsável pelo menor"
                            : "Foto do participante"}
                    </h3>
                    <div className="message">
                        Para validação do termo acima, é necessário que o
                        {isMinor
                            ? " responsável (o mesmo que assinou o termo de autorização) "
                            : " participante "}
                        envie uma selfie segurando um documento oficial com
                        foto.
                        <br />
                        <br />
                        <i>
                            <b>
                                Como tirar uma foto sua segurando o documento de
                                identidade:
                            </b>
                        </i>
                        <ul>
                            <li>Escolha um local bem iluminado</li>
                            <li>
                                Selecione a melhor qualidade de imagem do
                                celular ou câmera para tirar a foto
                            </li>
                            <li>Não use boné ou óculos de sol</li>
                            <li>
                                O documento deverá estar aberto (mostrando nome
                                e foto) e sem o plástico de proteção
                            </li>
                        </ul>
                    </div>
                    {existingSelfie}

                    {this.state.upload["selfie"] < 0 ? null : (
                        <div className="progress">
                            <div
                                style={{
                                    width: `${
                                        this.state.upload["selfie"] * 100
                                    }%`,
                                }}
                            />
                        </div>
                    )}
                    <p>
                        <button
                            className="button primary sm"
                            onClick={(e) => this.selectFile(1)}
                        >
                            Enviar selfie
                        </button>
                    </p>
                </div>

                <button
                    className="button"
                    disabled={isUploading}
                    onClick={(e) => this.cancel()}
                >
                    Voltar
                </button>
            </div>
        );
    }
}
