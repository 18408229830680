import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

/// Esta página é responsável por mostrar uma breve descrição do projeto, assim
/// como redirecionar à página de login
/// Se o usuário já estiver logado, exibir a página de dashboard

import { AppContext } from "../app-context";

export class WelcomePage extends Component {
    static contextType = AppContext;

    render() {
        let user = this.context && this.context.user;

        return (
            <div className="welcome-page">
                <div className="bg left"></div>
                <div className="dashboard welcome">
                    <p className="c img">
                        <img src="/2021/logo-seligaai.svg" alt="Se liga aí" />
                    </p>

                    <p className="c">
                        {user ? (
                            this.renderUserLink(user)
                        ) : (
                            <a href="/api/sign-in" className="button primary">
                                Bora se inscrever
                            </a>
                        )}
                    </p>

                    <p className="c img tvtem">
                        <img src="/2021/logo-tvtem.svg" alt="TV TEM" />
                    </p>
                </div>
                <div className="bg right"></div>

                <div className="hero bar" />
                <img
                    className="hero img left"
                    src="/2021/home-hero-left.svg"
                    alt=""
                />
                <img
                    className="hero img right"
                    src="/2021/home-hero-right.svg"
                    alt=""
                />
            </div>
        );
    }

    renderUserLink(user) {
        return (
            <Fragment>
                <Link to="/inscricoes" className="button primary">
                    Minhas inscrições
                </Link>
            </Fragment>
        );
    }
}
