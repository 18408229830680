import React, { Component } from "react";
import Axios from "axios";
import * as moment from "moment";

import {
    requiredValidator,
    validateForm,
    cpfValidator,
    dateValidator,
    phoneValidator,
    formatCep,
    formatCpf,
    formatPhone,
} from "../util";
import { ProfileField } from "./profile-field";

const DOCUMENT_REQUIRED_MESSAGE = "É necessário informar o RG ou CPF";
const INVALID_CPF = "O CPF informado é inválido";

const requiredDocumentsValidator = (field) => {
    let rg = document.querySelector("input[name=rg]");
    let cpf = document.querySelector("input[name=cpf]");

    let rgOk = requiredValidator(rg);
    let cpfOk = requiredValidator(cpf);

    return rgOk || cpfOk;
};

export class PlayerProfile extends Component {
    constructor(props) {
        super(props);

        let loading = false;
        let player = this.props.player || null;
        if (player && player.id) {
            loading = true;
        }

        let minorsOnly = [1, 3].includes(this.props.team.teamTypeId);
        this.state = {
            validators: this.getValidators(minorsOnly),
            loading,
            minorsOnly,
        };

        this.cpfOnChange = this.cpfOnChange.bind(this);
        this.cepOnChange = this.cepOnChange.bind(this);
        this.phoneOnChange = this.phoneOnChange.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        let player = this.props.player;
        if (player && player.id) {
            this.loadPlayer(player.id);
        }
    }

    loadPlayer(playerId) {
        let url = `/api/player/${playerId}`;
        Axios.get(url).then((res) => {
            let d = res.data;
            if (d.status === "OK") {
                if (d.player) this.cidadeId = d.player.cidadeId;

                this.setState({
                    loading: false,
                    player: d.player,
                });
            }
        });
    }

    getValidators(minorsOnly) {
        return {
            _enabled: true,
            name: {
                message: "O campo Nome é obrigatório",
                valid: true,
                validate: requiredValidator,
            },
            email: {
                message: "O campo E-mail é obrigatório",
                valid: true,
                validate: requiredValidator,
            },
            rg: {
                message: DOCUMENT_REQUIRED_MESSAGE,
                valid: true,
                validate: requiredDocumentsValidator,
            },
            cpf: [
                {
                    message: DOCUMENT_REQUIRED_MESSAGE,
                    valid: true,
                    validate: requiredDocumentsValidator,
                },
                {
                    message: INVALID_CPF,
                    valid: true,
                    validate: cpfValidator,
                },
            ],
            birthDate: [
                {
                    message: "O campo Data de nascimento é obrigatório",
                    valid: true,
                    validate: requiredValidator,
                },
                {
                    message: minorsOnly
                        ? "O participante precisa ter entre 12 e 18 anos completos até 31/12/2021"
                        : "O participante precisa ter no mínimo 12 anos completos até 31/12/2021",
                    valid: true,
                    validate: dateValidator,
                },
            ],
            cep: {
                message: "O campo CEP é obrigatório",
                valid: true,
                validate: requiredValidator,
            },
            address: {
                message: "O campo Logradouro é obrigatório",
                valid: true,
                validate: requiredValidator,
            },
            number: {
                message: "O campo Número é obrigatório",
                valid: true,
                validate: requiredValidator,
            },
            bairro: {
                message: "O campo Bairro é obrigatório",
                valid: true,
                validate: requiredValidator,
            },
            city: {
                message: "O campo Cidade é obrigatório",
                valid: true,
                validate: () => !!this.cidadeId,
            },
            phone: [
                {
                    message: "O campo é obrigatório",
                    valid: true,
                    validate: requiredValidator,
                },
                {
                    message: "Telefone inválido",
                    valid: true,
                    validate: phoneValidator,
                },
            ],
            phone2: [
                {
                    message: "Telefone inválido",
                    valid: true,
                    validate: phoneValidator,
                },
            ],
        };
    }

    validate(validatorKey = null, state = null) {
        let validators = this.state.validators;
        let res = validateForm(validators, validatorKey, state);

        let isFormValid = res[0];
        let newValidators = res[1];

        if (newValidators) {
            this.setState({
                validators: newValidators,
                ...(isFormValid ? state : null),
            });
        }

        return isFormValid;
    }

    cidadeId = null;

    cpfOnChange(e) {
        let vn = e.target.value.replace(/[^\d]/g, "");
        let v = formatCpf(vn);
        e.target.value = v;

        // validação
        if (vn.length === 11) {
            this.validate("cpf");
        }
    }

    cepOnChange(e) {
        let v = e.target.value.replace(/[^\d]/g, "");
        let vf = formatCep(v);
        e.target.value = vf;

        if (v.length === 8) {
            this.queryCep(v);
        }
    }

    phoneOnChange(e) {
        let phone = formatPhone(e.target.value);
        e.target.value = phone;
    }

    queryCep(cep) {
        let url = `/api/cep/${cep}`;

        const resetForm = () => {
            this.cidadeId = null;

            let inputs = document.querySelectorAll("input");
            inputs.forEach((input) => {
                switch (input.name) {
                    case "state":
                    case "city":
                        input.value = "";
                        break;

                    default:
                }
            });
        };

        Axios.get(url).then(
            (res) => {
                let d = res.data;
                if (d.status === "OK") {
                    this.cidadeId = d.cidadeId;

                    let inputs = document.querySelectorAll("input");
                    inputs.forEach((input) => {
                        switch (input.name) {
                            case "address":
                                if (d.logradouro) input.value = d.logradouro;
                                break;

                            case "bairro":
                                if (d.bairro) input.value = d.bairro;
                                break;

                            case "state":
                                input.value = d.uf;
                                break;
                            case "city":
                                input.value = d.cidade;
                                break;

                            default:
                        }
                    });
                } else {
                    resetForm();
                }
            },
            () => resetForm()
        );
    }

    save() {
        if (this.validate(null, { saving: true })) {
            let values = {
                cidadeId: this.cidadeId,
            };

            document.querySelectorAll(".form [name]").forEach((input) => {
                values[input.name] = input.value;
            });

            // Estamos editando o player referente ao usuário logado
            let user = this.props.user;
            if (user) {
                values["userId"] = user.id;
            }

            // Time
            let team = this.props.team;
            if (team) {
                values["team"] = team;
            }

            // Estamos realizando uma edição?
            let player = this.state.player;
            if (player && player.id) {
                values["id"] = player.id;
            } else {
                values["token"] = this.props.token;
            }

            return Axios.post("/api/player", values).then(
                (res) => {
                    this.setState({ saving: false });

                    let d = res.data;
                    switch (d.status) {
                        case "OK":
                            let onSave = this.props.onSave;
                            if (onSave) onSave(d);
                            break;

                        case "DocumentAlreadyRegistered":
                            alert(
                                "O número do documento informado já está cadastrado"
                            );
                            break;

                        default:
                    }
                },
                () => this.setState({ saving: false })
            );
        }
    }

    cancel() {
        let onSave = this.props.onSave;
        if (onSave) onSave(null);
    }

    render() {
        if (this.state.loading) return null;

        let title = this.props.title;
        let user = this.props.user;
        let player = this.state.player || {};
        let cidade = player.cidade || {};

        let birthDate = "";
        let mBirthDate = moment.utc(player.birthDate);
        if (mBirthDate.isValid()) birthDate = mBirthDate.format("YYYY-MM-DD");

        let cpf = formatCpf(player.cpf || "");
        let cep = formatCep(player.cep || "");

        let validators = this.state.validators;

        // Restrições de idade
        let config = this.props.config;
        let minBirthDate = this.state.minorsOnly
            ? config["MIN_BIRTH_DATE_MINOR"]
            : null;
        let maxBirthDate = this.state.minorsOnly
            ? config["MAX_BIRTH_DATE_MINOR"]
            : config["MAX_BIRTH_DATE"];

        let playerName = user ? user.name : player.name;
        let playerEmail = user ? user.email : player.email;

        return (
            <div className="form">
                {title ? <h1 className="form-title">{title}</h1> : null}

                <div className="box">
                    <ProfileField
                        label="Nome"
                        validators={validators}
                        validator-key={user ? null : "name"}
                    >
                        <input
                            type="text"
                            name="name"
                            disabled={!!user}
                            defaultValue={playerName}
                            maxLength="255"
                        />
                    </ProfileField>

                    <div className="fields">
                        <ProfileField
                            label="E-mail"
                            className="fill"
                            validators={validators}
                            validator-key={user ? null : "email"}
                        >
                            <input
                                type="email"
                                name="email"
                                disabled={!!user}
                                defaultValue={playerEmail}
                                maxLength="255"
                            />
                        </ProfileField>

                        <ProfileField
                            label="Telefone"
                            validators={validators}
                            validator-key="phone"
                        >
                            <input
                                type="phone"
                                name="phone"
                                onChange={this.phoneOnChange}
                                defaultValue={formatPhone(player.phone, true)}
                            />
                        </ProfileField>
                        <ProfileField
                            label="Telefone 2"
                            validators={validators}
                            validator-key="phone2"
                        >
                            <input
                                type="phone"
                                name="phone2"
                                onChange={this.phoneOnChange}
                                defaultValue={formatPhone(player.phone2, true)}
                            />
                        </ProfileField>
                    </div>

                    <div className="fields">
                        <ProfileField
                            label="RG"
                            validators={validators}
                            validator-key="rg"
                        >
                            <input
                                type="text"
                                name="rg"
                                defaultValue={player.rg}
                                maxLength="30"
                            />
                        </ProfileField>

                        <ProfileField
                            label="CPF"
                            validators={validators}
                            validator-key="cpf"
                        >
                            <input
                                type="text"
                                name="cpf"
                                defaultValue={cpf}
                                onChange={this.cpfOnChange}
                                maxLength="14"
                            />
                        </ProfileField>
                    </div>

                    <ProfileField
                        label="Data de nascimento"
                        validators={validators}
                        validator-key="birthDate"
                    >
                        <input
                            type="date"
                            name="birthDate"
                            defaultValue={birthDate}
                            min={minBirthDate}
                            max={maxBirthDate}
                            maxLength="10"
                        />
                    </ProfileField>

                    <h3>Endereço</h3>
                    <div className="fields">
                        <ProfileField
                            label="CEP"
                            validators={validators}
                            validator-key="cep"
                        >
                            <input
                                type="text"
                                name="cep"
                                defaultValue={cep}
                                maxLength="10"
                                onChange={this.cepOnChange}
                            />
                        </ProfileField>
                    </div>
                    <div className="fields">
                        <ProfileField
                            label="Logradouro"
                            className="fill"
                            validators={validators}
                            validator-key="address"
                        >
                            <input
                                type="text"
                                name="address"
                                defaultValue={player.logradouro}
                                maxLength="255"
                            />
                        </ProfileField>
                        <ProfileField
                            label="Bairro"
                            validators={validators}
                            validator-key="bairro"
                        >
                            <input
                                type="text"
                                name="bairro"
                                defaultValue={player.bairro}
                                maxLength="100"
                            />
                        </ProfileField>
                    </div>
                    <div className="fields">
                        <ProfileField
                            label="Número"
                            validators={validators}
                            validator-key="number"
                        >
                            <input
                                type="text"
                                name="number"
                                defaultValue={player.numero}
                                maxLength="30"
                            />
                        </ProfileField>
                        <ProfileField label="Complemento" className="fill">
                            <input
                                type="text"
                                name="complemento"
                                defaultValue={player.complemento}
                                maxLength="255"
                            />
                        </ProfileField>
                    </div>

                    <div className="fields">
                        <ProfileField
                            label="Cidade"
                            className="fill"
                            validators={validators}
                            validator-key="city"
                        >
                            <input
                                type="text"
                                name="city"
                                disabled
                                defaultValue={cidade.cidade}
                            />
                        </ProfileField>
                        <ProfileField label="Estado" className="fill">
                            <input
                                type="text"
                                name="state"
                                disabled
                                defaultValue={cidade.uf}
                            />
                        </ProfileField>
                    </div>
                </div>

                <button
                    className="button primary"
                    disabled={this.state.saving}
                    onClick={this.save}
                >
                    Salvar
                </button>
                <button className="button" onClick={this.cancel}>
                    Cancelar
                </button>
            </div>
        );
    }
}
